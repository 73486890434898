<template>
    <!-- 人员通行记录 -->
    <div class="box-card">
      <!-- 主要内容 -->
      <div v-if="dataDetail" class="mainBox">
        <div class="title">基本信息</div>
        <el-divider></el-divider>
        <el-form class="detailForm" label-width="150px" :model="dataDetail">
          <el-row :gutter="20">
            <el-col :span="18" style="text-align: left">
              <el-form-item label="姓名：">{{
                dataDetail.person_name
              }}</el-form-item>
               <el-form-item label="性别：">
                {{ dataDetail.sex === 1 ? "女" : "男" }}</el-form-item
              >
              <el-form-item label="证件类型：">{{
                getWayName("PersonCardType", dataDetail.card_type)
              }}</el-form-item>
               <el-form-item label="证件号码："
                >{{ dataDetail.card_num ? dataDetail.card_num : "无" }}
              </el-form-item>
              <el-form-item label="手机号码：">{{
                dataDetail.phone ? dataDetail.phone : "无"
              }}</el-form-item>
              <el-form-item label="核验类型：">{{
                getWayName("VerificationType", dataDetail.verification_type)
              }}</el-form-item>
              <el-form-item label="人员身份：">{{
                getWayName("PersonType", dataDetail.per_type)
              }}</el-form-item>
              <el-form-item label="所属组织：" v-if="dataDetail.organize_name">{{
                dataDetail.organize_name
              }}</el-form-item>
              <el-form-item label="设备名称：">{{
                dataDetail.facesluice_name
              }}</el-form-item>
                <el-form-item label="出入类型：">{{
                getWayName("FaceExitType", dataDetail.exit_type)
              }}</el-form-item>
              <el-form-item label="出入时间：">{{
                validDateTime(dataDetail.go_time)
              }}</el-form-item>
            </el-col>
            <el-col :span="6" style="text-align: left">
              <el-image
              class="in-out-image"
              fit="cover"
              :src="dataDetail.register_pic"
              :preview-src-list="[dataDetail.register_pic]"
              v-if="dataDetail.register_pic"
              :z-index="9999"
            >
              <div slot="error" class="image-slot">
                <i class="iconfont lebo-picerr"></i>
                <span>加载失败</span>
              </div>
            </el-image>
            <el-image
              class="in-out-image"
              fit="contain"
              :z-index="9000"
              :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
              :preview-src-list="[
               require('@/assets/img/noMessage/noPeopleInLight.png'),
              ]"
              v-else></el-image>
              <div class="maskBox">已录入图片</div>
            <el-image
              class="in-out-image"
              fit="cover"
              :src="dataDetail.pic"
              :preview-src-list="[dataDetail.pic]"
              :z-index="9999"
              v-if="dataDetail.pic"
            >
              <div slot="error" class="image-slot">
                <i class="iconfont lebo-picerr"></i>
                <span>加载失败</span>
              </div>
            </el-image>
            <el-image
              class="in-out-image"
              fit="contain"
              :z-index="9000"
              :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
              :preview-src-list="[
               require('@/assets/img/noMessage/noPeopleInLight.png'),
              ]"
              v-else></el-image>
            <div class="maskBox">现场抓拍图片</div>

          </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </template>

<script>
import {
  getPersonRecordPageList,
  getPersonRecordDetailt,
  getFaceDeviceList,
  getOrganizationSeleteList
} from '@/api/securityApi'
import { mapMutations, mapGetters, mapState } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      dataDetail: {}
    }
  },
  created () {
    this.requestDetails()
  },
  mounted () {},
  computed: {
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async requestDetails () {
      const res = await getPersonRecordDetailt({ record_id: this.id })
      this.dataDetail = res && res.Code === 200 ? res.Data : null
    },
    // 返回
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
  }
  .detailForm {
    .maskBox {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, .5);
    margin-bottom: 10px;
  }
    /deep/ .el-form-item {
      margin-bottom: 0px;
    }
    .el-form-item__content {
      text-align: left;
    }
  }
  // .el-input__inner {
  //   height: 36px;
  //   line-height: 36px;
  // }
  .button_box {
    display: flex;
  }
  .button_box .el-button {
    margin-left: 15px;
    width: auto;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-button {
    width: auto !important;
  }

  .imagefu img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .itemone {
    display: flex;
    justify-content: flex-end;
  }
  .itemtwo {
    display: flex;
    justify-content: flex-start;
  }
  .form-in-item {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: start;
  }
  .in-out-image {
    // max-width: 70%;
    width: 100%;
    height: 200px;
    // width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // background-color: #ccc;
    /deep/ .image-slot {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f5f7fa;
      color: #999;
      .iconfont{
        font-size: 36px;
      }
    }
    /deep/ .el-image__inner {
      object-fit: contain;
      align-self: flex-start;
    }
  }
  .box-card {
    position: relative;
    padding-bottom: 30px;
    margin-top:0 !important;
    .mainBox{
      max-height: 650px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .checkButton {
        position: absolute;
        right: 50px;
    }

    .checkButton2 {
        position: absolute;
        right: 150px;
    }

    .checkButton3 {
        position: absolute;
        right: 250px;
    }
}
  </style>
